import { BsInputCursorText } from 'react-icons/bs';
import {
  AdvancedFormBuilderTypes,
  BasicFormBuilderTypes,
  FieldSchema,
  FormBuilderSchema,
  formBuilderType,
  FormBuilderTypes,
  Sections,
} from '../formElements/formBuilderSchema';
import TextFieldSchemaBuilder from '../formElements/TextFieldSchemaBuilder';
import { v4 as uuidv4 } from 'uuid';
import {
  MdFiberPin,
  MdLink,
  MdMiscellaneousServices,
  MdOutlineAccessTime,
  MdOutlineArticle,
  MdOutlineBackup,
  MdOutlineCheckBox,
  MdOutlinePerson,
  MdOutlinePin,
  MdSmartButton,
  MdTextFields,
  MdViewAgenda,
  MdContactPhone,
} from 'react-icons/md';
import EmailSchemaBuilder from '../formElements/EmailSchemaBuilder';
import { IoCalendar, IoCall, IoCheckbox, IoImage, IoLocation, IoMail, IoRadioButtonOnOutline, IoToggle } from 'react-icons/io5';
import PhoneSchemaBuilder from '../formElements/PhoneSchemaBuilder';
import LinkSchemaBuilder from '../formElements/LinkSchemaBuilder';
import DatePickerSchemaBuilder from '../formElements/DatePickerSchemaBuilder';
import TimePickerSchemaBuilder from '../formElements/TimePickerSchemaBuilder';
import NumberSchemaBuilder from '../formElements/NumberSchemaBuilder';
import RadioSchemaBuilder from '../formElements/RadioSchemaBuilder';
import MCQSchemaBuilder from '../formElements/MCQSchemaBuilder';
import YesNoSchemaBuilder from '../formElements/YesNoSchemaBuilder';
import AddressSchemaBuilder from '../formElements/AddressSchemaBuilder';
import ImageSchemaBuilder from '../formElements/ImageSchemaBuilder';
import CheckBoxSchemaBuilder from '../formElements/CheckBoxSchemaBuilder';
import ButtonSchemaBuilder from '../formElements/ButtonSchemaBuilder';
import { FaLanguage, FaSignature } from 'react-icons/fa';
import SignatureSchemaBuilder from '../formElements/SignatureSchemaBuilder';
import { AiFillBank } from 'react-icons/ai';
import DocumentPreview from '../formElements/advancedElements/documentPreview';
import CheckboxDisable from '../formElements/CheckboxDisable';

export const fields = [
  'name',
  'email',
  'gender',
  'dateOfBirth',
  'correspondenceLanguage',
  'phoneNumber',
  'optionalPhoneNumber',
  'socialInsuranceNumber',
  'completeAddress',
  'postalCode',
  'apartment',
  'emergencyContact',
  'signatureImageUrl',
  'chequebookImageUrl',
  'workPermitImageUrl',
  'workPermitEndDate',
  'address',
  'documentIdImageUrl',
  'firstName',
  'lastName',
  'nickname',
  'bankAccountNumber',
  'routingNumber',
  'branchTransitNumber',
  'financialInstitutionNumber',
  'addressLineOne',
  'addressLineTwo',
  'city',
  'region',
  'postalCode',
  'country',
  'contractDetailsWordings',
  'emergencyContactFirstName',
  'emergencyContactLastName',
  'emergencyContactPhoneNumber',
  'codeofconductandethics',
  'privacynoticeforemployees',
  'electronicmonitoringpolicy',
];

export enum schemaFields {
  name = 'name',
  email = 'email',
  gender = 'gender',
  dateOfBirth = 'dateOfBirth',
  correspondenceLanguage = 'correspondenceLanguage',
  phoneNumber = 'phoneNumber',
  optionalPhoneNumber = 'optionalPhoneNumber',
  socialInsuranceNumber = 'socialInsuranceNumber',
  socialSecurityNumber = 'socialSecurityNumber',
  completeAddress = 'completeAddress',
  apartment = 'apartment',
  emergencyContact = 'emergencyContact',
  signatureImageUrl = 'signatureImageUrl',
  chequebookImageUrl = 'chequebookImageUrl',
  workPermitImageUrl = 'workPermitImageUrl',
  workPermitEndDate = 'workPermitEndDate',
  address = 'address',
  documentIdImageUrl = 'documentIdImageUrl',
  firstName = 'firstName',
  lastName = 'lastName',
  nickname = 'nickname',
  bankAccountNumber = 'bankAccountNumber',
  routingNumber = 'routingNumber',
  branchTransitNumber = 'branchTransitNumber',
  financialInstitutionNumber = 'financialInstitutionNumber',
  addressLineOne = 'addressLineOne',
  addressLineTwo = 'addressLineTwo',
  city = 'city',
  region = 'region',
  postalCode = 'postalCode',
  country = 'country',
  contractDetailsWordings = 'contractDetailsWordings',
  emergencyContactFirstName = 'emergencyContactFirstName',
  emergencyContactLastName = 'emergencyContactLastName',
  emergencyContactPhoneNumber = 'emergencyContactPhoneNumber',
  endDate = 'endDate',
  codeofconductandethics = 'codeofconductandethics',
  privacynoticeforemployees = 'privacynoticeforemployees',
  electronicmonitoringpolicy = 'electronicmonitoringpolicy',
}

export const schemaFieldsByType: Record<FormBuilderTypes, string[] | null> = {
  [formBuilderType.text]: [
    formBuilderType.text,
    schemaFields.name,
    schemaFields.firstName,
    schemaFields.lastName,
    schemaFields.nickname,
    schemaFields.apartment,
  ],
  [formBuilderType.number]: [formBuilderType.number, schemaFields.postalCode],
  [formBuilderType.email]: [],
  [formBuilderType.phone]: [formBuilderType.phone, schemaFields.phoneNumber, schemaFields.optionalPhoneNumber],
  [formBuilderType.date]: [formBuilderType.date, schemaFields.dateOfBirth, schemaFields.endDate],
  [formBuilderType.radio]: [],
  [formBuilderType.address]: [],
  [formBuilderType.gender]: [],
  [formBuilderType.image]: [formBuilderType.image, schemaFields.documentIdImageUrl],
  [formBuilderType.file]: [],
  [formBuilderType.yesNo]: [],
  [formBuilderType.checkbox]: [],
  [formBuilderType.link]: [],
  [formBuilderType.button]: [],
  [schemaFields.bankAccountNumber]: [schemaFields.bankAccountNumber],
  [schemaFields.routingNumber]: [schemaFields.routingNumber],
  [schemaFields.branchTransitNumber]: [schemaFields.branchTransitNumber],
  [schemaFields.financialInstitutionNumber]: [schemaFields.financialInstitutionNumber],
  [formBuilderType.info]: [],
  [formBuilderType.singleCheckbox]: [],
  [formBuilderType.time]: [],
  [formBuilderType.documentReview]: [],
  [formBuilderType.checkboxDisable]: [],
  [formBuilderType.correspondenceLanguage]: [],
  [formBuilderType.sin]: [],
  [formBuilderType.ssn]: [],
  [formBuilderType.signature]: [],
  // ADVANCED
  [formBuilderType.sinAdvanced]: [],
  [formBuilderType.ssnAdvanced]: [],
  [formBuilderType.documentPreview]: [],
  [formBuilderType.canadianBank]: [],
  [formBuilderType.americanBank]: [],
  [formBuilderType.signatureAdvanced]: [],
  [formBuilderType.addressValidator]: [],
  // [formBuilderType.confirmation]: [],
  [formBuilderType.earliestStartDate]: [],
  [formBuilderType.emergencyContact]: [],
  [formBuilderType.miscellaneous]: [],
  // PAGE ELEMENTS
  [formBuilderType.section]: [],
};

type DefaultValueProps = Pick<
  FieldSchema,
  | 'text'
  | 'label'
  | 'isInfo'
  | 'link'
  | 'optionList'
  | 'validations'
  | 'previewPlaceholders'
  | 'documentReview'
  | 'disabled'
  | 'disabledId'
  | 'isFrontEndOnly'
  | 'hideFieldSelector'
  | 'followUpQuestionList'
  | 'min'
  | 'max'
  | 'enableWorkPermit'
> & {
  inputType?: Pick<FieldSchema, 'inputType'>['inputType'];
  isFollowUp?: Pick<FieldSchema, 'isFollowUp'>['isFollowUp'];
  field?: Pick<FieldSchema, 'field'>['field'];
} & { isFollowUp?: Pick<FieldSchema, 'isFollowUp'>['isFollowUp'] };

interface getSchemaValuesProps extends Pick<FormBuilderSchema, 'type' | 'label' | 'icon' | 'component' | 'hidden' | 'section'> {
  defaultValue: DefaultValueProps | DefaultValueProps[];
  panel?: Pick<FormBuilderSchema, 'panel'>['panel'];
}

function getSchemaValues({
  defaultValue = {},
  label: _label,
  icon,
  panel = 'basic',
  component,
  type,
  hidden = false,
  section,
}: getSchemaValuesProps): FormBuilderSchema {
  function getDefaultValue(value: DefaultValueProps) {
    const {
      text = { en: '', fr: '' },
      label,
      isInfo = false,
      optionList = null,
      validations,
      previewPlaceholders,
      inputType,
      isFollowUp = false,
      field,
      documentReview = null,
      disabled = false,
      disabledId,
      isFrontEndOnly = false,
      hideFieldSelector = false,
      followUpQuestionList = null,
      min = null,
      max = null,
      enableWorkPermit = null,
      link = null,
    } = value;

    return {
      timestamp: Date.now(),
      id: uuidv4(),
      required: true,
      order: 1,
      validations,
      isInfo,
      inputType: inputType ?? type,
      text,
      label: label ?? { en: _label, fr: '' },
      optionList,
      previewPlaceholders,
      section,
      isFollowUp,
      field: field ?? type,
      documentReview,
      disabled,
      disabledId,
      isFrontEndOnly,
      hideFieldSelector,
      followUpQuestionList,
      min,
      max,
      enableWorkPermit,
      link,
    };
  }

  const currentDefaultValues = Array.isArray(defaultValue) ? defaultValue.map(getDefaultValue) : getDefaultValue(defaultValue);

  return {
    type,
    component,
    props: {},
    defaultValue: currentDefaultValues,
    icon,
    label: _label,
    panel,
    hidden,
    section,
  };
}

export const basicFormBuilderSchemas: Record<BasicFormBuilderTypes, FormBuilderSchema> = {
  [formBuilderType.text]: getSchemaValues({
    type: formBuilderType.text,
    component: TextFieldSchemaBuilder,
    defaultValue: {
      min: 3,
      max: 500,
    },
    icon: BsInputCursorText,
    label: 'Text',
  }),
  [formBuilderType.info]: getSchemaValues({
    type: formBuilderType.info,
    component: TextFieldSchemaBuilder,
    defaultValue: { isInfo: true },
    icon: MdTextFields,
    label: 'Info',
  }),
  [formBuilderType.email]: getSchemaValues({
    type: formBuilderType.email,
    component: EmailSchemaBuilder,
    defaultValue: {
      text: {
        en: 'Enter your email address.',
        fr: 'Entrez votre adresse e-mail.',
      },
      min: 5,
      max: 100,
    },
    icon: IoMail,
    label: 'Email',
  }),
  [formBuilderType.phone]: getSchemaValues({
    type: formBuilderType.phone,
    component: PhoneSchemaBuilder,
    defaultValue: { text: { en: 'What is your phone number?', fr: 'Quel est votre numéro de téléphone?' }, min: 10, max: 20 },
    icon: IoCall,
    label: 'Phone',
  }),
  [formBuilderType.link]: getSchemaValues({
    type: formBuilderType.link,
    component: LinkSchemaBuilder,
    defaultValue: { link: { en: '' } },
    icon: MdLink,
    label: 'Link',
  }),
  [formBuilderType.date]: getSchemaValues({
    type: formBuilderType.date,
    component: DatePickerSchemaBuilder,
    defaultValue: {},
    icon: IoCalendar,
    label: 'Date',
  }),
  [formBuilderType.time]: getSchemaValues({
    type: formBuilderType.time,
    component: TimePickerSchemaBuilder,
    defaultValue: {},
    icon: MdOutlineAccessTime,
    label: 'Time',
  }),
  [formBuilderType.number]: getSchemaValues({
    type: formBuilderType.number,
    component: NumberSchemaBuilder,
    defaultValue: { min: 10, max: 100 },
    icon: MdOutlinePin,
    label: 'Number',
  }),
  [formBuilderType.radio]: getSchemaValues({
    type: formBuilderType.radio,
    component: RadioSchemaBuilder,
    defaultValue: {
      optionList: [
        { key: 'A', id: uuidv4(), text: { en: '<p>Option 1</p>' } },
        { key: 'B', id: uuidv4(), text: { en: '<p>Option 2</p>' } },
      ],
    },
    icon: IoRadioButtonOnOutline,
    label: 'Single Choice',
  }),
  [formBuilderType.checkbox]: getSchemaValues({
    type: formBuilderType.checkbox,
    component: MCQSchemaBuilder,
    defaultValue: {
      optionList: [
        { key: 'A', id: uuidv4(), text: { en: '<p>Option 1</p>' } },
        { key: 'B', id: uuidv4(), text: { en: '<p>Option 2</p>' } },
      ],
    },
    icon: IoCheckbox,
    label: 'Multiple Choice',
  }),
  [formBuilderType.gender]: getSchemaValues({
    type: formBuilderType.radio,
    component: RadioSchemaBuilder,
    defaultValue: {
      text: { en: 'What is your gender?' },
      optionList: [
        { key: 'A', id: uuidv4(), text: { en: '<p>Female</p>' } },
        { key: 'B', id: uuidv4(), text: { en: '<p>Male</p>' } },
        { key: 'C', id: uuidv4(), text: { en: '<p>Non-Binary</p>' } },
        { key: 'D', id: uuidv4(), text: { en: '<p>Undeclared</p>' } },
      ],
      field: schemaFields.gender,
      hideFieldSelector: true,
    },
    icon: MdOutlinePerson,
    label: 'Gender',
  }),
  [formBuilderType.yesNo]: getSchemaValues({
    type: formBuilderType.yesNo,
    component: YesNoSchemaBuilder,
    defaultValue: {
      optionList: [
        { key: 'Yes', id: uuidv4(), text: { en: 'Yes', fr: 'Oui' } },
        { key: 'No', id: uuidv4(), text: { en: 'No', fr: 'Non' } },
      ],
    },
    icon: IoToggle,
    label: 'Yes/No',
  }),
  [formBuilderType.address]: getSchemaValues({
    type: formBuilderType.address,
    component: AddressSchemaBuilder,
    defaultValue: { text: { en: 'What is your current address? Use the button below to search addresses.' } },
    icon: IoLocation,
    label: 'Address',
  }),
  [formBuilderType.image]: getSchemaValues({
    type: formBuilderType.image,
    component: ImageSchemaBuilder,
    defaultValue: {
      validations: {
        acceptType: ['image/jpg', 'image/jpeg', 'image/png'],
        maxFileSize: 2 * 1024 * 1024, // 2MB
      },
    },
    icon: IoImage,
    label: 'Image',
  }),
  [formBuilderType.file]: getSchemaValues({
    type: formBuilderType.file,
    component: ImageSchemaBuilder,
    defaultValue: {
      validations: {
        acceptType: ['application/pdf'],
        maxFileSize: 2 * 1024 * 1024, // 2MB
      },
    },
    icon: MdOutlineBackup,
    label: 'File',
  }),
  [formBuilderType.documentReview]: getSchemaValues({
    type: formBuilderType.documentReview,
    component: DocumentPreview,
    defaultValue: {
      documentReview: {
        confirmText: { en: 'Confirm', fr: 'Confirmer' },
        description: { en: 'Please upload your documents.', fr: 'Veuillez uploader vos documents.' },
        acknowledgeContent: {
          en: 'I acknowledge that I have uploaded all the required documents.',
          fr: 'Je confirme avoir uploader tous les documents requis.',
        },
        url: {
          en: '',
        },
      },
    },
    icon: MdOutlineBackup,
    label: 'Document',
  }),
  [formBuilderType.singleCheckbox]: getSchemaValues({
    type: formBuilderType.singleCheckbox,
    component: CheckBoxSchemaBuilder,
    defaultValue: {},
    icon: MdOutlineCheckBox,
    label: 'Checkbox',
    hidden: true,
  }),
  [formBuilderType.button]: getSchemaValues({
    type: formBuilderType.button,
    component: ButtonSchemaBuilder,
    defaultValue: {},
    icon: MdSmartButton,
    label: 'Button',
    hidden: true,
  }),
  [formBuilderType.bankAccountNumber]: getSchemaValues({
    type: formBuilderType.bankAccountNumber,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: {
        en:
          '<strong>Bank Account Number:</strong> The Bank Account Number is the group of 7 to 12 numbers after the Financial Institution Number at the bottom of your cheque.',
      },
      previewPlaceholders: { placeholder: '00 0000', disableMinMax: true },
      field: schemaFields.bankAccountNumber,
    },
    icon: MdOutlinePin,
    label: 'Bank Account Number',
    hidden: true,
  }),
  [formBuilderType.routingNumber]: getSchemaValues({
    type: formBuilderType.routingNumber,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: {
        en:
          '<strong>Routing Number:</strong> The Routing Number is the group of 9 numbers after the Financial Institution Number at the bottom of your cheque.',
      },
      previewPlaceholders: { placeholder: '000000000', disableMinMax: true },
      field: schemaFields.routingNumber,
    },
    icon: MdOutlinePin,
    label: 'Routing Number',
    hidden: true,
  }),
  [formBuilderType.branchTransitNumber]: getSchemaValues({
    type: formBuilderType.branchTransitNumber,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: {
        en:
          '<strong>Branch Transit Number:</strong> The Branch Transit Number is the first group of 5 numbers at the bottom of your cheque.',
      },
      previewPlaceholders: { placeholder: '00000', disableMinMax: true },
      field: schemaFields.branchTransitNumber,
    },
    icon: MdOutlinePin,
    label: 'Branch Transit Number',
    hidden: true,
  }),
  [formBuilderType.financialInstitutionNumber]: getSchemaValues({
    type: formBuilderType.financialInstitutionNumber,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: {
        en:
          '<strong>Financial Institution Number:</strong> The Financial Institution Number is the group of 3 numbers after the Branch Transit Number at the bottom of your cheque.',
      },
      previewPlaceholders: { placeholder: '000', disableMinMax: true },
      field: schemaFields.financialInstitutionNumber,
    },
    icon: MdOutlinePin,
    label: 'Financial Institution Number',
    hidden: true,
  }),

  [formBuilderType.checkboxDisable]: getSchemaValues({
    type: formBuilderType.checkboxDisable,
    component: CheckboxDisable,
    defaultValue: {
      isFrontEndOnly: true,
    },
    icon: MdOutlinePin,
    label: 'checkbox disable',
    hidden: true,
  }),
  [formBuilderType.correspondenceLanguage]: getSchemaValues({
    type: formBuilderType.correspondenceLanguage,
    component: RadioSchemaBuilder,
    defaultValue: {
      inputType: formBuilderType.radio,
      text: { en: 'Please select a language' },
      optionList: [
        { key: 'A', id: uuidv4(), text: { en: '<p>English</p>', fr: '<p>Anglais</p>' } },
        { key: 'B', id: uuidv4(), text: { en: '<p>French</p>', fr: '<p>Français</p>' } },
      ],
      field: schemaFields.correspondenceLanguage,
      hideFieldSelector: true,
    },
    icon: FaLanguage,
    label: 'Correspondence Language',
    section: 'signature',
  }),

  [formBuilderType.sin]: getSchemaValues({
    type: formBuilderType.sin,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: { en: 'Social Insurance Number', fr: 'Numéro de sécurité sociale' },
      label: { en: 'SIN', fr: 'Numéro de sécurité sociale' },
      previewPlaceholders: { placeholder: '000-000-000', disableMinMax: true },
      field: schemaFields.socialInsuranceNumber,
    },
    icon: MdOutlinePin,
    label: 'Number',
    hidden: true,
  }),
  [formBuilderType.ssn]: getSchemaValues({
    type: formBuilderType.ssn,
    component: NumberSchemaBuilder,
    defaultValue: {
      text: { en: 'Social Security Number', fr: 'Numéro de sécurité sociale' },
      label: { en: 'SSN', fr: 'Numéro de sécurité sociale' },
      previewPlaceholders: { placeholder: '000-000-000', disableMinMax: true },
      field: schemaFields.socialSecurityNumber,
    },
    icon: MdOutlinePin,
    label: 'Number',
    hidden: true,
  }),
  [formBuilderType.signature]: getSchemaValues({
    type: formBuilderType.signature,
    component: SignatureSchemaBuilder,
    defaultValue: {
      text: { en: 'Signature', fr: 'Signature' },
      label: { en: 'Signature', fr: 'Signature' },
      field: schemaFields.signatureImageUrl,
    },
    icon: FaSignature,
    label: 'Signature',
    hidden: true,
  }),
};

const disableIds = {
  sinWorkPermit: 'sinWorkPermit',
  bankVoidCheque: 'bankVoidCheque',
};

export const advancedFormBuilderSchemas: Record<AdvancedFormBuilderTypes, FormBuilderSchema> = {
  [formBuilderType.sinAdvanced]: getSchemaValues({
    type: formBuilderType.sinAdvanced,
    component: null,
    defaultValue: [
      {
        text: { en: 'Social Insurance Number', fr: 'Numéro de sécurité sociale' },
        ...basicFormBuilderSchemas.sin.defaultValue,
      },
      {
        ...basicFormBuilderSchemas.checkboxDisable.defaultValue,
        text: { en: 'Enable work permit if it starts from 9', fr: 'Activer le permis de travail si il commence le 9' },
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.date.defaultValue,
        text: { en: 'What is the end date of your work permit?', fr: 'Quand finit votre permis de travail?' },
        label: { en: 'Work permit end date', fr: 'Date de fin du permis de travail' },
        field: schemaFields.workPermitEndDate,
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: 'We will need a photo of your valid work permit.',
          fr: 'Nous avons besoin d’une photo de votre permis de travail.',
        },
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: 'Make sure you follow these instructions when taking your photo:',
          fr: 'Assurez-vous de suivre ces instructions lors de la prise de votre photo:',
        },
        disabledId: disableIds.sinWorkPermit,
      },
      {
        ...basicFormBuilderSchemas.image.defaultValue,
        text: {
          en:
            'All of the pages of the work permit, and the work permit’s end date, must be clearly visible in the photo preview.',
          fr:
            'Toutes les pages du permis de travail ainsi que la date de fin du permis doivent être clairement visibles dans la prévisualisation de la photo.',
        },
        label: { en: 'Work permit photo', fr: 'Photo du permis de travail' },
        field: schemaFields.workPermitImageUrl,
        disabledId: disableIds.sinWorkPermit,
      },
    ],
    section: Sections.sin,
    icon: MdFiberPin,
    label: 'SIN',
    panel: 'advanced',
  }),
  [formBuilderType.ssnAdvanced]: getSchemaValues({
    type: formBuilderType.ssnAdvanced,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.ssn.defaultValue,
        text: { en: 'Social Security Number', fr: 'Numéro de sécurité sociale' },
      },
    ],
    section: Sections.ssn,
    icon: MdFiberPin,
    label: 'SSN',
    panel: 'advanced',
  }),
  [formBuilderType.signatureAdvanced]: getSchemaValues({
    type: formBuilderType.signatureAdvanced,
    component: null,
    defaultValue: [{ ...basicFormBuilderSchemas.signature.defaultValue, text: { en: 'Signature' } }],
    icon: FaSignature,
    label: 'Signature',
    panel: 'advanced',
    section: Sections.signature,
  }),
  [formBuilderType.documentPreview]: getSchemaValues({
    type: formBuilderType.documentPreview,
    component: null,
    defaultValue: [
      {
        ...{
          ...basicFormBuilderSchemas.documentReview.defaultValue,
          documentReview: {
            ...((basicFormBuilderSchemas.documentReview.defaultValue as any).documentReview ?? {}),

            description: {
              en: 'You need to consent to this Code after you have read it.',
              fr: 'Vous devez consentir à ce Code après avoir lu ce document.',
            },
            acknowledgeContent: {
              en:
                '<p>I acknowledge that I have read and understood the <span data-tag="true">[business_name]</span> Privacy Notice for Employees and signify that I will comply with the rules, policies and procedures set forth in this employee privacy policy.</p>',
              fr:
                '<p> Je reconnais avoir lu et compris le <span data-tag="true">[business_name]</span> Notice de Confidentialité des Employés et m’y engage.</p>',
            },
          },
        },
        field: schemaFields.codeofconductandethics,
        text: {
          en:
            '<p>Please review the <span data-tag="true">[business_name]</span> of Conduct and Ethics. It addresses various topics and includes the rules and guidelines for personal conduct and ethical decisions in the course of your work.</p>',
          fr: '<p> Veuillez lire attentivement le <span data-tag="true">[business_name]</span> Code de conduite et éthique.</p>',
        },
        label: {
          en: 'Code of Conduct and Ethics',
          fr: 'Code de conduite et éthique',
        },
      },
      {
        ...{
          ...basicFormBuilderSchemas.documentReview.defaultValue,
          documentReview: {
            ...((basicFormBuilderSchemas.documentReview.defaultValue as any).documentReview ?? {}),

            description: {
              en: 'You need to consent to this Code after you have read it.',
              fr: 'Vous devez consentir à ce Code après avoir lu ce document.',
            },
            acknowledgeContent: {
              en:
                '<p>I acknowledge that I have read and understood the <span data-tag="true">[business_name]</span> Privacy Notice for Employees and signify that I will comply with the rules, policies and procedures set forth in this employee privacy policy.',
              fr:
                '<p>Je reconnais avoir lu et compris le <span data-tag="true">[business_name]</span> Notice de Confidentialité des Employés et m’y engage.</p>',
            },
          },
        },
        field: schemaFields.privacynoticeforemployees,
        text: {
          en:
            '<p>Please review the <span data-tag="true">[business_name]</span> Privacy Notice for Employees that informs you on how your personal information is collected, for which purpose and how you may exercise certain privacy rights.</p>',
          fr:
            '<p> Veuillez lire attentivement le <span data-tag="true">[business_name]</span> Notice de Confidentialité des Employés.</p>',
        },
        label: {
          en: 'Privacy Notice for Employees',
          fr: 'Notice de Confidentialité des Employés',
        },
      },
      {
        ...{
          ...basicFormBuilderSchemas.documentReview.defaultValue,
          documentReview: {
            ...((basicFormBuilderSchemas.documentReview.defaultValue as any).documentReview ?? {}),

            description: {
              en: 'You need to consent to this Employee Privacy Policy after you have read it.',
              fr: 'Vous devez consentir à ce Code après avoir lu ce document.',
            },
            acknowledgeContent: {
              en:
                '<p>I acknowledge that I have read and understood the <span data-tag="true">[business_name]</span> Privacy Notice for Employees and signify that I will comply with the rules, policies and procedures set forth in this employee privacy policy.</p>',
              fr:
                '<p>Je reconnais avoir lu et compris le <span data-tag="true">[business_name]</span> Notice de Confidentialité des Employés et m’y engage.</p>',
            },
          },
        },
        field: schemaFields.electronicmonitoringpolicy,
        text: {
          en:
            '<p>Please review the Electronic Monitoring Policy that governs the use of <span data-tag="true">[business_name]</span> equipment and the tools used to monitor productive work.</p>',
          fr:
            '<p> Veuillez lire attentivement la <span data-tag="true">[business_name]</span> Politique de surveillance électronique.</p>',
        },
        label: {
          en: 'Electronic Monitoring Policy',
          fr: 'Politique de surveillance électronique',
        },
      },
    ],
    section: 'documentPreview',
    icon: MdOutlineArticle,
    label: 'Document Preview',
    panel: 'advanced',
  }),
  [formBuilderType.canadianBank]: getSchemaValues({
    type: formBuilderType.canadianBank,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en:
            'In order to set you up in our system, we will need your banking information. These numbers can be found on a cheque or within your online banking portal.',
          fr:
            'Pour vous inscrire dans notre système, nous avons besoin de vos informations bancaires. Ces chiffres peuvent être trouvés sur un chèque ou dans le portail en ligne de votre banque.',
        },
      },
      {
        ...basicFormBuilderSchemas.branchTransitNumber.defaultValue,
        label: { en: 'Branch Transit Number', fr: 'Numéro de transit de la banque' },
      },
      {
        ...basicFormBuilderSchemas.financialInstitutionNumber.defaultValue,
        label: { en: 'Financial Institution Number', fr: 'Numéro de l’institution financière' },
      },
      {
        ...basicFormBuilderSchemas.bankAccountNumber.defaultValue,
        label: { en: 'Bank Account Number', fr: 'Numéro de compte bancaire' },
      },
      {
        ...basicFormBuilderSchemas.checkboxDisable.defaultValue,
        text: { en: 'Enable void cheque image upload', fr: 'Activer l’upload de la photo du chèque vide' },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: 'We now need a photo of your void cheque.',
          fr: 'Nous avons maintenant besoin d’une photo de votre chèque vide.',
        },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: 'Make sure you follow these instructions when taking your photo:',
          fr: 'Assurez-vous de suivre ces instructions lorsque vous prenez votre photo:',
        },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.image.defaultValue,
        text: {
          en:
            'SPECIMEN or VOID must be written on the cheque. Make sure all the numbers on the void cheque are clearly visible in the photo preview. Handwritten information is not accepted.',
          fr:
            'SPECIMEN ou VOID doit être écrit sur le chèque. Assurez-vous de voir clairement tous les nombres sur le chèque vide. Les informations manuscrites ne sont pas acceptées.',
        },
        disabledId: disableIds.bankVoidCheque,
        field: schemaFields.chequebookImageUrl,
      },
    ],
    section: Sections.canadianBank,
    icon: AiFillBank,
    label: 'Canadian Bank',
    panel: 'advanced',
  }),
  [formBuilderType.americanBank]: getSchemaValues({
    type: formBuilderType.americanBank,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en:
            'In order to set you up in our system, we need your banking information. These numbers can be found on a cheque or within your bank’s online portal.',
          fr:
            'Pour vous inscrire dans notre système, nous avons besoin de vos informations bancaires. Ces chiffres peuvent être trouvés sur un chèque ou dans le portail en ligne de votre banque.',
        },
      },
      {
        ...basicFormBuilderSchemas.routingNumber.defaultValue,
        label: { en: 'Routing Number', fr: 'Numéro de routage' },
      },
      {
        ...basicFormBuilderSchemas.bankAccountNumber.defaultValue,
        label: { en: 'Bank Account Number', fr: 'Numéro de compte bancaire' },
      },
      {
        ...basicFormBuilderSchemas.checkboxDisable.defaultValue,
        text: { en: 'Enable void cheque image upload', fr: 'Activer l’upload de la photo du chèque vide' },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: 'We now need a photo of your void cheque.',
          fr: 'Nous avons maintenant besoin d’une photo de votre chèque vide.',
        },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: {
          en: 'Make sure you follow these instructions when taking your photo:',
          fr: 'Assurez-vous de suivre ces instructions lorsque vous prenez votre photo:',
        },
        disabledId: disableIds.bankVoidCheque,
      },
      {
        ...basicFormBuilderSchemas.image.defaultValue,
        text: {
          en:
            'SPECIMEN or VOID must be written on the cheque. Make sure all the numbers on the void cheque are clearly visible in the photo preview. Handwritten information is not accepted.',
          fr:
            'SPECIMEN ou VOID doit être écrit sur le chèque. Assurez-vous de voir clairement tous les nombres sur le chèque vide. Les informations manuscrites ne sont pas acceptées.',
        },
        disabledId: disableIds.bankVoidCheque,
        field: schemaFields.chequebookImageUrl,
      },
    ],
    section: Sections.americanBank,
    icon: AiFillBank,
    label: 'American Bank',
    panel: 'advanced',
  }),
  [formBuilderType.earliestStartDate]: getSchemaValues({
    type: formBuilderType.earliestStartDate,
    component: null,
    defaultValue: [
      {
        inputType: formBuilderType.date,
        text: {
          en:
            '<p>To proceed, let us know the earliest date that you are available to work! This position can start as early as <span data-tag="true">[position_start_date]</span> </p>',
        },
      },
    ],
    section: 'earliest start date',
    icon: IoCalendar,
    label: 'Earliest Start Date',
    panel: 'advanced',
  }),
  [formBuilderType.addressValidator]: getSchemaValues({
    type: formBuilderType.addressValidator,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.address.defaultValue,
        text: { en: 'What is your current address? Use the button below to search addresses.' },
        label: { en: 'Current Address' },
      },
      {
        ...basicFormBuilderSchemas.info.defaultValue,
        text: { en: 'Thank you for your patience. We have very few questions left.' },
      },
      {
        ...basicFormBuilderSchemas.number.defaultValue,
        text: { en: 'Enter your postal code' },
        previewPlaceholders: { placeholder: '000 000', disableMinMax: true },
        label: { en: 'Postal Code' },
      },
      addressValidatorApartmentNumber(),
    ],
    section: 'address validator',
    icon: IoLocation,
    label: 'Address validator',
    panel: 'advanced',
  }),
  [formBuilderType.emergencyContact]: getSchemaValues({
    type: formBuilderType.emergencyContact,
    component: null,
    defaultValue: [emergencyContact()],
    section: Sections.emergencyContact,
    icon: MdContactPhone,
    label: 'Emergency Contact Details',
    panel: 'advanced',
  }),
  [formBuilderType.miscellaneous]: getSchemaValues({
    type: formBuilderType.miscellaneous,
    component: null,
    defaultValue: [
      {
        ...basicFormBuilderSchemas.text.defaultValue,
        text: { en: 'Any additional information you would like to share with us?' },
        field: schemaFields.contractDetailsWordings,
        label: { en: 'Contract Details', fr: 'Informations sur le contrat' },
        previewPlaceholders: { placeholder: 'Enter text here', disableMinMax: true },
      },
    ],
    section: Sections.miscellaneous,
    icon: MdMiscellaneousServices,
    label: 'Miscellaneous',
    panel: 'advanced',
  }),
};

export type FormBuilderSchemas = Record<FormBuilderTypes, FormBuilderSchema>;

export const formBuilderSchemas: FormBuilderSchemas = {
  // basic elements
  ...basicFormBuilderSchemas,

  // Page Elements
  [formBuilderType.section]: getSchemaValues({
    type: formBuilderType.section,
    component: null,
    defaultValue: {},
    icon: MdViewAgenda,
    label: 'Section',
    panel: 'page-element',
  }),

  // Advanced elements
  ...advancedFormBuilderSchemas,
};

function addressValidatorApartmentNumber() {
  const parentID = uuidv4();
  const option1Id = uuidv4();
  const option2Id = uuidv4();
  const schema: FieldSchema = {
    timestamp: Date.now(),
    id: parentID,
    inputType: formBuilderType.yesNo,
    required: true,
    text: {
      en: 'Does your address have an apartment number?',
    },
    order: 13,
    section: 'address validator',
    optionList: [
      { key: 'Yes', id: option1Id, text: { en: 'Yes', fr: 'Oui' } },
      { key: 'No', id: option2Id, text: { en: 'No', fr: 'Non' } },
    ],
    isFollowUp: false,
    followUpQuestionList: [
      {
        timestamp: Date.now(),
        id: uuidv4(),
        inputType: formBuilderType.number,
        required: true,
        optionId: option1Id,
        text: { en: 'Enter your apartment number.' },
        parent: parentID,
        order: 1,
        label: { en: 'Apartment number' },
        previewPlaceholders: {
          placeholder: 'Apartment Number',
          disableMinMax: true,
        },
        isFollowUp: true,
        field: schemaFields.apartment,
      },
    ],
    field: schemaFields.address,
  };
  return schema;
}

function emergencyContact() {
  const parentID = uuidv4();
  const option1Id = uuidv4();
  const option2Id = uuidv4();
  const schema: FieldSchema = {
    timestamp: Date.now(),
    id: parentID,
    inputType: formBuilderType.yesNo,
    required: true,
    text: {
      en: 'Do you have someone we can reach in case of an emergency?',
      fr: 'Avez-vous quelqu’un que nous pouvons joindre en cas d’urgence?',
    },
    order: 13,
    section: Sections.emergencyContact,
    optionList: [
      { key: 'Yes', id: option1Id, text: { en: 'Yes', fr: 'Oui' } },
      { key: 'No', id: option2Id, text: { en: 'No', fr: 'Non' } },
    ],
    isFollowUp: false,
    followUpQuestionList: [
      {
        timestamp: Date.now(),
        id: uuidv4(),
        inputType: formBuilderType.text,
        required: true,
        optionId: option1Id,
        text: { en: 'What is their first name?', fr: 'Quel est leur nom de famille?' },
        parent: parentID,
        order: 1,
        label: { en: 'First name', fr: 'Prénom' },
        previewPlaceholders: {
          placeholder: 'Input here',
          disableMinMax: true,
        },
        isFollowUp: true,
        field: schemaFields.emergencyContactFirstName,
      },
      {
        timestamp: Date.now(),
        id: uuidv4(),
        inputType: formBuilderType.text,
        required: true,
        optionId: option1Id,
        text: { en: 'What is their last name?', fr: 'Quel est leur prénom?' },
        parent: parentID,
        order: 1,
        label: { en: 'Last name', fr: 'Nom' },
        previewPlaceholders: {
          placeholder: 'Input here',
          disableMinMax: true,
        },
        isFollowUp: true,
        field: schemaFields.emergencyContactLastName,
      },
      {
        timestamp: Date.now(),
        id: uuidv4(),
        inputType: formBuilderType.phone,
        required: true,
        optionId: option1Id,
        text: {
          en: 'What is their phone number? We will only contact them in case of an emergency.',
          fr: 'Quel est leur numéro de téléphone? Nous ne nous contacterons que dans le cas d’urgence.',
        },
        parent: parentID,
        order: 1,
        label: { en: 'Phone number', fr: 'Numéro de telephone' },
        previewPlaceholders: {
          placeholder: 'Input here',
          disableMinMax: true,
        },
        isFollowUp: true,
        field: schemaFields.emergencyContactPhoneNumber,
      },
    ],
    field: schemaFields.emergencyContact,
  };
  return schema;
}
