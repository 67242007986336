import { Input } from '@chakra-ui/core';
import React from 'react';
import { useStoreState } from '../../../../models/hooks';
import { LabelSchema } from '../formElements/formBuilderSchema';
import { useParams, useRouteMatch } from 'react-router-dom';
import useErrorHandling from '../useErrorHandling';
import theme from '../../../../styles/customTheme';
import ErrorWrapper from '../errorHandling/ErrorWrapper';
import { useHireFormContext } from '../HireForm.context';

interface TemplateNameInputProps {
  templateName: LabelSchema;
  setTemplateName: React.Dispatch<React.SetStateAction<LabelSchema>>;
}

const TemplateNameInput = React.memo(({ templateName, setTemplateName }: TemplateNameInputProps) => {
  const { currentTemplate } = useStoreState((state) => state.hrFormTemplate);
  const { language } = useHireFormContext();

  const templateId = useParams<{ templateId?: string }>()?.templateId ?? undefined;
  const isDuplicate = useRouteMatch('/hire-form/:templateId/duplicate');

  const [name, setName] = React.useState<string>('');

  const { validateError, error, hasError, inputRef } = useErrorHandling({
    path: ['name'],
    valdationFor: 'template_name',
    onValidation: (value) => {
      return { ...templateName, [language]: value };
    },
  });

  React.useEffect(() => {
    setName(templateName[language] ?? '');
  }, [templateName, language]);

  React.useEffect(() => {
    if (!currentTemplate || !templateId) return;

    if (currentTemplate.name) {
      let name = currentTemplate.name;
      if (isDuplicate) {
        name = name + '_copy';
      }
      setTemplateName((prev) => ({ ...prev, [language]: name }));
    }

    // eslint-disable-next-line
  }, [currentTemplate, templateId]);

  function handleInputBlur() {
    setTemplateName({ ...templateName, [language]: name });
  }

  const handleChange = (val: string) => {
    setName(val);

    validateError(val);
  };

  return (
    <ErrorWrapper ref={inputRef} error={error?.message!} hasError={hasError}>
      <Input
        variant="unstyled"
        width="100%"
        onBlur={handleInputBlur}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        value={name}
        placeholder="Enter template name"
        flexGrow={1}
        fontSize="normal"
        fontWeight="bold"
        size="md"
        borderRadius={theme.radii.sm}
        ref={inputRef}
        _placeholder={{
          fontWeight: 'normal',
        }}
      />
    </ErrorWrapper>
  );
});

export default TemplateNameInput;
