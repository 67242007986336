import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { Divider, HStack, Stack } from '@chakra-ui/core';
import InputPreview from './InputPreview';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';
import InputMinMaxSelector from './InputMinMaxSelector';

export default function EmailSchemaBuilder({ ...props }: SchemaBuilderProps) {
  return (
    <Stack>
      <HStack justify="space-between">
        <LabelSchemaBuilder {...props} />
        <HStack flexShrink={0}>
          <Divider orientation="vertical" />
          {props?.labelRightRenderer}
        </HStack>
      </HStack>
      <FormElementTextBuilder {...props} placeholder="Enter email address here..." />
      <InputPreview
        value={props?.defaultValue?.previewPlaceholders?.placeholder ?? 'example@domain.com'}
        rightContent={<InputMinMaxSelector {...props} />}
      />
    </Stack>
  );
}
