export default {
  notAuthorized: {
    notAuthorizedMsg: `Vous n'êtes pas autorisé à voir cette page. Contactez votre administrateur.`,
    goToHomeBtn: ` Retourner à l'accueil`,
  },
  administrationSideBarMenuItems: {
    jobListingUrl: `URL pour l’affichage de postes d’emploi `,
    uploadCompanyLogo: `Télécharger le logo de l'entreprise`,
    companyLogo: `Logo de l'entreprise`,
    certnIntegration: 'Intégration de Certn',
    positionManagement: 'Gestion des postes',
    lowAvailability: `Gestion des disponibilités de l'équipe`,
    recruiterManagement: 'Gestion des recruteurs',
    appointmentCalendarLabel: `Calendrier`,
    configuration: 'Configuration',
    unresponsivePeriod: 'Gestion des candidats qui ne répond pas\n',
    languageEvaluationPhrasesLabel: 'Évaluation de la langue',
    videoGallery: 'Galerie vidéo',
    webHookURL: 'Endpoint du webhook',
    imageGallery: "Galerie d'images",
  },
  calendar: {
    sidebarTitle: 'Recruteurs',
    positionTitle: 'Postes',
    all: 'Tous',
    followed: 'Suivi',
    sidebarRecruiterSearchPlaceholder: 'Rechercher parmis les recruteurs',
    sidebarPositionSearchPlaceholder: 'Rechercher les postes',
    sidebarPositionClearAll: 'Réinitialiser tout',
    toolTipWarningMessage: `Pour voir les entrevues de {{recruiterName}}, veuillez décocher un autre recruteur.`,
    toolTipWarningMessageSnackBar: `Pour voir les entrevues, veuillez décocher un autre recruteur.`,
    backBtnText: 'Retour',
  },
  hireFormAPIKey: {
    label: 'Clés API',
    subDescription: 'Copy API key for hire form',
    copyKeyBtnLabel: 'Copy Key',
    generateNewAPIKey: 'Générer nouvelle clé API',
    copiedLabel: 'Clé API copiée',
    renewedSuccessMessage: 'New API key generated successfully',
    alertModal: {
      title: 'Générer nouvelle clé API',
      description:
        'Ceci va générer une nouvelle clé API. Cette clé API sera utilisée à travers votre intégration avec AppyHere. Souhaitez-vous continuer?',
      confirm: 'Confirmer',
      cancel: 'Annuler',
    },
    alertModalCopy: {
      title:
        "Copier et stocker cette clé API dans un emplacement sécurisé. Vous ne serez plus capable de l'accéder après la fermeture de cette fenêtre.",
      copyBtn: 'Copier clé API',
    },
  },
  modernHire: {
    sidebarTitle: 'Évaluation des compétences',
    form: {
      userName: `Nom d'utilisateur`,
      password: 'Mot de passe',
      modernHireSubDomain: 'Sous-domaine Modern Hire',
      submit: 'Soumettre',
    },
  },
  languageEvaluation: {
    cardTitle: 'Évaluation de la langue ',
    selectLanguage: 'Choisir la langue',
    french: 'Français',
    english: 'Anglais',
    phraseTitle: 'Phrase titre',
    phrase: 'Phrase',
    add: 'Ajouter',
    update: 'Mettre à jour',
    delete: 'Supprimer',
    deletePhraseTitle: 'Supprimer une phrase',
    deletePhraseDetail: 'Cette phrase sera définitivement supprimée. Cette action ne peut être annulée.',
    confirm: 'Confirmer',
    cancel: 'ANNULER',
    phraseLabelRequired: "L'étiquette de la phrase est requise",
    phraseDescriptionRequired: 'La phrase est obligatoire',
  },
  webHookUrl: {
    title: 'Endpoint du webhook pour les candidats embauchés',
    subtitle:
      "Vous pouvez définir l'URL du endpoint pour le API des candidats embauchés ici. Ce endpoint sera où que nous allons envoyer les renseignements d'un candidat lorsqu'il est embauché.",
    inputPlaceHolder: "Entrer l'URL du endpoint",
    successMsg: "L'URL du webhook a été mise à jour correctement.",
    errorMsg: "L'URL du webhook ne pouvait pas être mise à jour.",
    validUrl: 'Seulement les formats de URL valides sont acceptés..',
  },
  imageGallery: {
    loading: 'Attendez quelques instants...',
    noImages: 'Aucune image trouvée',
    uploadError: "Le téléchargement de l'image a échoué.",
    imagePreviewTitle: "Apercu d'image",
  },
  imageGalleryModal: {
    triggerButtonLabel: 'Ajouter une image',
    title: "Galerie d'images",
    subtitle: 'Ajouter et gérer les images de cette organisation.',
    refreshButtonToolTip: 'Rafraîchir la galerie',
    uploadButtonLabel: 'Ajouter une image',
    uploadPreviewCancelButtonLabel: 'Annuler',
    uploadPreviewSaveButtonLabel: 'Sauvegarder',
    loadFailedToastTitle: "Incapable d'accéder la galerie.",
    loadFailedToastMessage: 'Une erreur est survenue. Veuillez essayer de nouveau.',
    invalidImageType: "Type d'image invalide. Seulement les PNG ou les JPG sont acceptés.",
    invalidImageSize: "Taille d'image invalide. Seulement les images de taille inférieure ou égale à 2MB sont acceptées.",
    sortAscending: 'Tri ascendant',
    sortDescending: 'Tri descendant',
    searchInputPlaceholder: 'Rechercher',
    errorLoadingImages: "Erreur de téléchargement de l'image",
  },
};
