import { Box, Stack, Text, useToast } from '@chakra-ui/core';
import React from 'react';
import { FormBuilderSchema, formBuilderType, Sections } from '../formElements/formBuilderSchema';
import theme from '../../../../styles/customTheme';
import isArray from 'lodash/isArray';
import { v4 as uuidv4 } from 'uuid';
import { useHireFormContext } from '../HireForm.context';

interface FormComponentProps {
  component: FormBuilderSchema;
}

const FormComponent = React.memo(({ component }: FormComponentProps) => {
  const toast = useToast();
  const { formSchema, setFormSchema } = useHireFormContext();

  let { icon: Icon, label, section: sectionName, defaultValue, type, panel } = component;
  if (component.type === formBuilderType.sin) {
    Icon = SsnIcon;
  }

  const isSectionSchemaPresent = formSchema.some((section) => section.section === sectionName) && panel === 'advanced';
  const isBankSchemaPresent =
    (sectionName === Sections.americanBank || sectionName === Sections.canadianBank) &&
    formSchema.some((section) => section.section === Sections.americanBank || section.section === Sections.canadianBank);

  const isSSNOrSINPresent =
    (sectionName === Sections.sin || sectionName === Sections.ssn) &&
    formSchema.some((section) => section.section === Sections.sin || section.section === Sections.ssn);

  function handleOnClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();

    if (isSectionSchemaPresent) {
      toast({
        description: `"${sectionName?.toUpperCase()}" can only be inserted once time per template`,
        status: 'error',
        isClosable: true,
      });
      return;
    }

    if (isBankSchemaPresent) {
      toast({
        description: `either ${Sections.americanBank} or ${Sections.canadianBank} can only be inserted once per template`,
        status: 'error',
        isClosable: true,
      });
      return;
    }

    const schemas = [...formSchema];
    const lastSection = schemas[schemas.length - 1];
    const secondLastSection = schemas[schemas.length - 2];

    const isSignature = type === formBuilderType.signature;
    const hasSignature = formSchema
      .flatMap((section) => section.fields)
      .some((field) => field.inputType === formBuilderType.signature);

    if (panel === 'advanced' && isArray(defaultValue)) {
      const newSchema = {
        section: sectionName!,
        fields: defaultValue?.map((field) => ({
          ...field,
          timestamp: Date.now(),
          // id: uuidv4(),
        })),
        isAdvanced: true,
        id: uuidv4(),
      };

      if (hasSignature) {
        schemas.splice(schemas.length - 1, 0, newSchema);
      } else if (isSignature || (schemas.length === 1 && schemas[0].fields.length === 0)) {
        schemas.push(newSchema);
      } else {
        schemas.push(newSchema);
      }

      setFormSchema(schemas);
    } else if (panel === 'basic' && !isArray(defaultValue) && lastSection) {
      const field = { ...defaultValue, id: uuidv4(), timestamp: Date.now() };
      const newSection = { section: 'section' + (schemas.length + 1), fields: [field], id: uuidv4() };

      if (hasSignature) {
        if (secondLastSection?.isAdvanced) {
          schemas.splice(schemas.length - 1, 0, newSection);
        } else {
          schemas[schemas.length - 2].fields.push(field);
        }
      } else {
        if (lastSection?.isAdvanced) {
          schemas.push(newSection);
        } else {
          schemas[schemas.length - 1].fields.push(field);
        }
      }
      setFormSchema(schemas);
    } else if (panel === 'page-element') {
      const newFormSchema = [...formSchema];
      const newSection = { section: 'section' + (schemas.length + 1), fields: [], id: uuidv4() };
      if (hasSignature) {
        newFormSchema.splice(newFormSchema.length - 1, 0, newSection);
      } else {
        newFormSchema.push(newSection);
      }
      setFormSchema(newFormSchema);
    }
  }

  function handleDragStart(e: React.DragEvent<HTMLDivElement>) {
    e.dataTransfer.setData('type', type);
    e.dataTransfer.effectAllowed = 'copy';
    e.dataTransfer.dropEffect = 'copy';
  }

  return (
    <Box
      draggable={!isSectionSchemaPresent && !isBankSchemaPresent && !isSSNOrSINPresent}
      borderRadius={theme.radii.md}
      backgroundColor="#F3F6F9"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      style={
        isSectionSchemaPresent || isBankSchemaPresent || isSSNOrSINPresent
          ? { cursor: 'not-allowed', opacity: 0.6 }
          : { cursor: 'pointer' }
      }
      onClick={handleOnClick}
      onDragStart={handleDragStart}
      width="100%"
      height="100%"
    >
      <Stack spacing={2} align="center">
        <Icon size={24} />
        <Text
          fontWeight={700}
          margin={0}
          textAlign="center"
          isTruncated
          noOfLines={2}
          maxWidth={200}
          sx={{ fontSize: label.length > 20 ? 'xs' : 'sm', lineHeight: label.length > 20 ? '1.1em' : '1.4em' }}
        >
          {label}
        </Text>
      </Stack>
    </Box>
  );
});

export const SsnIcon = () => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 0.5H18C19.11 0.5 20 1.39 20 2.5V14.5C20 15.61 19.11 16.5 18 16.5H2C0.89 16.5 0 15.61 0 14.5L0.0100002 2.5C0.0100002 1.39 0.89 0.5 2 0.5ZM9 11.5H10.5V5.5H9V11.5ZM16.8 11.5H18V5.5H16.75V9L14.25 5.5H13V11.5H14.25V8L16.8 11.5ZM7 5.5H2V9H5.5V10H2V11.5H7V8H3.5V7H7V5.5Z"
      fill="#586073"
    />
  </svg>
);

export default FormComponent;
