import {
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  FormControl,
  FormLabel,
  Stack,
  theme,
  Text,
  HStack,
  ButtonGroup,
} from '@chakra-ui/core';
import React from 'react';
import ReactQuill from 'react-quill';
import { MdOutlineBorderColor } from 'react-icons/md';
import { CloseIcon } from '@chakra-ui/icons';
import { CreateTemplateSettingsRequest } from '../hireForm/templateSettings/templateSettings.interface';
import { useStoreActions } from '../../../models/hooks';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import MobilePreviewView from '../hireForm/mobilePreview/MobilePreviewView';
import { Language } from '../hireForm/formElements/formBuilderSchema';

export default function DocumentSettings() {
  const [isCreating, setIsCreating] = React.useState(false);
  const [devicePreview, setDevicePreview] = React.useState(true);

  const [selectedLanguage] = React.useState<Language>('en');
  const [formData, setFormData] = React.useState<CreateTemplateSettingsRequest>({
    hireFormDisclaimer: {},
    consentText: {
      en: 'I consent',
      fr: 'Je consent',
    },
  });

  const { postOrganizationHireFormSettings, getOrganizationHireFormSettings } = useStoreActions((state) => state.account);
  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account ?? '';

  React.useEffect(() => {
    const getOrganizationSettings = async () => {
      const response = await getOrganizationHireFormSettings({ accountId });
      setFormData({
        ...formData,
        ...response.data.data,
      });
    };
    getOrganizationSettings(); // eslint-disable-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const onSubmit = async () => {
    try {
      if (isCreating) return;
      setIsCreating(true);
      await postOrganizationHireFormSettings({
        accountId,
        hireFormSettings: formData,
      });
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <HStack align="flex-start">
      <Stack padding={theme.space[2]} backgroundColor={theme.colors.white} borderRadius={theme.radii.md} align="center">
        <FormControl>
          <FormLabel fontWeight={700} color="#1A202C" marginBottom={theme.space[4]}>
            <HStack justify="space-between">
              <Text>Disclaimer Text</Text>
              <Button
                variant="link"
                fontSize={12}
                fontWeight="normal"
                textDecoration="underline"
                colorScheme="blue"
                onClick={() => setDevicePreview(true)}
              >
                Preview
              </Button>
            </HStack>
            <Text fontSize="sm" color="gray.500" fontWeight={400}>
              This is a standard disclaimer template. Customize the text below to suit your needs. Ensure that all necessary legal
              and compliance information is included to provide clarity and transparency to your users or audience.
            </Text>
          </FormLabel>
          <Stack
            spacing={2}
            background="#EDF2F7"
            padding={0}
            borderRadius={theme.radii.md}
            _focusWithin={{
              outline: `1px solid ${theme.colors.blue[300]}`,
            }}
            paddingBottom={theme.space[4]}
            sx={{
              '& p': {
                margin: 'revert !important',
              },
            }}
          >
            <QuillEditor
              value={formData.hireFormDisclaimer[selectedLanguage]}
              onChange={(value) => {
                setFormData({
                  ...formData,
                  hireFormDisclaimer: { ...formData.hireFormDisclaimer, [selectedLanguage]: value },
                });
              }}
              language={selectedLanguage}
            />
          </Stack>
        </FormControl>
        <Box>
          <EditableButton
            value={formData.consentText[selectedLanguage]}
            onChange={(value) => {
              setFormData({
                ...formData,
                consentText: { ...formData.consentText, [selectedLanguage]: value },
              });
            }}
          />
        </Box>
        <HStack justify="flex-end" marginTop={theme.space[4]} width="100%">
          <ButtonGroup size="sm">
            <Button colorScheme="red" disabled={isCreating}>
              Cancel
            </Button>
            <Button colorScheme="blue" isLoading={isCreating} onClick={onSubmit}>
              Update
            </Button>
          </ButtonGroup>
        </HStack>
      </Stack>
      {devicePreview && (
        <Box
          backgroundColor={theme.colors.white}
          borderRadius={theme.radii.md}
          padding={theme.space[4]}
          flexBasis="350px"
          flexShrink={0}
          height="100%"
        >
          <HStack align="flex-start" justify="space-between" marginBottom={theme.space[2]}>
            <Text fontWeight={700} color="#1A202C" fontSize={theme.fontSizes.lg}>
              Device Preview
            </Text>
            <Button size="sm" variant="square" onClick={() => setDevicePreview(false)}>
              <CloseIcon />
            </Button>
          </HStack>
          <PreviewView formData={formData} selectedLanguage={selectedLanguage} />
        </Box>
      )}
    </HStack>
  );
}

interface QuillEditorProps {
  value: string;
  onChange: (value: string) => void;
  language: Language;
}

const QuillEditor = ({ value, onChange, language }: QuillEditorProps) => {
  const quillRef = React.useRef<ReactQuill>(null);
  const htmlString = {
    en: `
      <p>By completing the information in the AppyHere application, I consent to have AppyHere collecting the following personal information for the purpose of reviewing my employment application with Dollarama L.P.: name, phone number, email, date of birth, address, current employment status information, void cheque, social insurance number, work permit (if applicable).</p>
      <p>I understand that my information will be used by AppyHere and Dollarama L.P. in the context of recruiting for a position at Dollarama L.P. and will be shared with their staff, members of its group, or agents only in the aforementioned context.</p>
      <p>I agree to disclose if I have family members who work at the same store I am applying for.</p>
      <p>To avoid conflicts or the appearance of conflicts of interest, Dollarama L.P. prohibits spouses and family members from working in the same store if there is a relationship of subordinates between the two employees. If this is the case, I will make Dollarama L.P. aware so that opportunities at another store can be explored.</p>
      <p>It is my duty to disclose any conflict of interest, or Dollarama L.P. reserves the right to terminate my employment without further notice.</p>
      <p>If I am living in the province of Québec and am under 16 years of age and do not have my Secondary 5 diploma OR if I am 16 years old and in the middle of the school year, I must notify my Store Manager and comply with the CNESST’s “Loi sur l’encadrement du travail des enfants”.</p>
      <p>Therefore, it is on a voluntary basis that I agree to complete the information and entirely assume responsibility for my answers. It is also my understanding that my participation can be interrupted at any time. For any information regarding the Privacy Policy of Dollarama, please visit the Legal Matters section at <a href="https://www.dollarama.com/en-CA/legal-matters/Privacy-Policy" rel="noopener noreferrer" target="_blank">https://www.dollarama.com/en-CA/legal-matters/Privacy-Policy</a>.</p>
      <p>If you are ready to start, click on the button below.</p>
    `,
    fr: `
      
      <p>En complétant les informations dans l'application AppyHere, je consens à ce qu'AppyHere collecte les informations personnelles suivantes dans le but d'examiner ma demande d'emploi auprès de Dollarama S.E.C. : nom, numéro de téléphone, courriel, date de naissance, adresse, information sur mon statut d'emploi actuel, chèque annulé, numéro d'assurance sociale, permis de travail (le cas échéant).</p>
      <p>Je comprends que mes informations seront utilisées par AppyHere et Dollarama S.E.C. dans le cadre du recrutement pour un poste chez Dollarama S.E.C. et seront partagées avec leur personnel, les membres de leur groupe ou leurs agents uniquement dans ce contexte.</p>
      <p>J'accepte de divulguer si des membres de ma famille travaillent dans le même magasin pour lequel je postule.</p>
      <p>Pour éviter les conflits ou l'apparence de conflits d'intérêts, Dollarama S.E.C. interdit aux conjoints et aux membres de la famille de travailler dans le même magasin s'il existe une relation de subordination entre les deux employés. Si c'est le cas, j'en informerai Dollarama S.E.C. afin que des opportunités dans un autre magasin puissent être envisagées.</p>
      <p>Il est de mon devoir de divulguer tout conflit d'intérêts, sinon Dollarama S.E.C. se réserve le droit de mettre fin à mon emploi sans préavis.</p>
      <p>Si je réside dans la province de Québec et que j'ai moins de 16 ans et que je n'ai pas obtenu mon diplôme de cinquième secondaire OU si j'ai 16 ans et que je suis en cours d'année scolaire, je dois en informer mon gérant de magasin et me conformer à la “Loi sur l'encadrement du travail des enfants” de la CNESST.</p>
      <p>Ainsi, c'est de manière volontaire que j'accepte de compléter les informations et d'assumer entièrement la responsabilité de mes réponses. Je comprends également que ma participation peut être interrompue à tout moment. Pour toute information concernant la politique de confidentialité de Dollarama, veuillez visiter la section Affaires Juridiques à l'adresse suivante : <a href="https://www.dollarama.com/en-CA/legal-matters/Privacy-Policy" rel="noopener noreferrer" target="_blank">https://www.dollarama.com/en-CA/legal-matters/Privacy-Policy</a>.</p>
      <p>Si vous êtes prêt à commencer, cliquez sur le bouton ci-dessous.</p>
    `,
  };

  React.useEffect(() => {
    if (quillRef.current && !value) {
      const quill = quillRef.current.getEditor(); // Get the Quill instance
      quill.clipboard.dangerouslyPasteHTML(htmlString?.[language]); // Paste the HTML content
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <Box
      sx={{
        '& .quill,  & .ql-container': {
          border: 'none !important',
        },
        '& .ql-toolbar': {
          borderTop: 'none !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
        },
      }}
    >
      <ReactQuill
        value={value}
        onChange={onChange}
        ref={quillRef}
        theme="snow"
        placeholder="Paste text or links here..."
        modules={{
          toolbar: [
            [{ header: '1' }, { header: '2' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link'],
          ],
        }}
        formats={['header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link']}
      />
    </Box>
  );
};

interface EditableInputProps {
  value: string;
  onChange: (value: string) => void;
}
const EditableButton = ({ value, onChange }: EditableInputProps) => {
  return (
    <Button size="md" colorScheme="orange" rightIcon={<MdOutlineBorderColor size={16} color={theme.colors.gray[800]} />}>
      <Editable value={value} selectAllOnFocus={false}>
        <EditablePreview />
        <EditableInput onChange={(e) => onChange(e.target.value)} />
      </Editable>
    </Button>
  );
};

interface PreviewViewProps {
  formData: CreateTemplateSettingsRequest;
  selectedLanguage: string;
}

const PreviewView = ({ formData, selectedLanguage }: PreviewViewProps) => {
  return (
    <Stack
      sx={{
        '& p': {
          margin: 'revert !important',
        },
      }}
    >
      <MobilePreviewView hideProgress height="100%">
        <Stack>
          <Stack spacing={1} align="center" padding={theme.space[5]}>
            <Box
              dangerouslySetInnerHTML={{ __html: formData.hireFormDisclaimer[selectedLanguage] }}
              fontSize="sm"
              lineHeight={theme.fontSizes.lg}
              color="#718096"
            />
            <Button colorScheme="orange" isFullWidth>
              {formData.consentText[selectedLanguage]}
            </Button>
          </Stack>
        </Stack>
      </MobilePreviewView>
    </Stack>
  );
};
