import {
  HStack,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Spacer,
  Stack,
  NumberInput,
  theme,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  PopoverFooter,
  Button,
  ButtonGroup,
  Text,
  Box,
} from '@chakra-ui/core';
import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { MdKeyboardArrowDown } from 'react-icons/md';

export default function InputMinMaxSelector({ defaultValue, ...props }: SchemaBuilderProps) {
  const [max, setMax] = React.useState(defaultValue.max);
  const [min, setMin] = React.useState(defaultValue.min);

  const hasValuesChanged = React.useMemo(() => {
    return max !== defaultValue.max || min !== defaultValue.min;
  }, [max, min, defaultValue]);

  React.useEffect(() => {
    setMax(defaultValue.max);
    setMin(defaultValue.min);
  }, [defaultValue]);

  function handleClose(onClose: () => void) {
    onClose();
  }

  function handleSave(onClose: () => void) {
    props.onChange({ ...defaultValue, min, max });
    onClose();
  }

  return (
    <HStack>
      <Popover placement="bottom" closeOnBlur={false}>
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <Box backgroundColor="#E2E8F0" paddingX={theme.space[2]} paddingY={theme.space[1]} borderRadius={theme.radii.md}>
                <HStack>
                  <Text fontSize="sm" cursor="pointer">
                    Character Limit ({defaultValue.min} - {defaultValue.max})
                  </Text>
                  <MdKeyboardArrowDown size={20} />
                </HStack>
              </Box>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverHeader pt={4} fontWeight="bold" border="0">
                  Characters limits
                </PopoverHeader>

                <PopoverCloseButton disabled={hasValuesChanged} />
                <PopoverBody>
                  <Text fontSize="sm">
                    Set the minimum and maximum number of characters allowed in this field. User must input between {min} and{' '}
                    {max} characters while answering this question.
                  </Text>
                  <Spacer marginY={3} />
                  <HStack>
                    <Stack flexShrink={0} flexGrow={1} spacing={1}>
                      <Text fontSize="xs" fontWeight="bold" whiteSpace="nowrap">
                        Min :
                      </Text>
                      <NumberInput
                        allowMouseWheel
                        step={10}
                        value={min || 0}
                        min={0}
                        max={max || 1000}
                        onChange={(e) => setMin(Number(e))}
                        borderRadius={theme.radii.md}
                        size="sm"
                        maxW={'8rem'}
                      >
                        <NumberInputField borderRadius={theme.radii.md} />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </Stack>

                    <Stack flexShrink={0} flexGrow={1} spacing={1}>
                      <Text fontSize="xs" fontWeight="bold" whiteSpace="nowrap">
                        Max :
                      </Text>
                      <NumberInput
                        allowMouseWheel
                        step={10}
                        value={max || 0}
                        min={min || 0}
                        max={1000}
                        maxW={'8rem'}
                        onChange={(e) => setMax(Number(e))}
                        borderRadius={theme.radii.md}
                        size="sm"
                      >
                        <NumberInputField borderRadius={theme.radii.md} />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </Stack>
                  </HStack>
                  <Spacer marginTop={3} />
                </PopoverBody>
                <PopoverFooter d="flex" alignItems="center" justifyContent="space-between" pb={4}>
                  <Button
                    colorScheme="gray"
                    variant="ghost"
                    onClick={() => {
                      setMax(defaultValue.max);
                      setMin(defaultValue.min);
                    }}
                    disabled={!hasValuesChanged}
                    size="sm"
                  >
                    Reset
                  </Button>
                  <ButtonGroup size="sm">
                    <Button colorScheme="gray" variant="outline" onClick={() => handleClose(onClose)}>
                      Cancel
                    </Button>
                    <Button colorScheme="blue" onClick={() => handleSave(onClose)} disabled={!hasValuesChanged}>
                      Done
                    </Button>
                  </ButtonGroup>
                </PopoverFooter>
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    </HStack>
  );
}
