import { Box, Divider, HStack, Spacer, Stack, SystemStyleObject } from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../../styles/customTheme';

type DropPositionT = 'top' | 'bottom';
type DropTargetT = { index: number; position: DropPositionT };
interface DroppableElementProps {
  children: React.ReactNode;
  isDisabled?: boolean;
  isLastElement?: boolean;
  onDrop?: (type: string, position?: DropPositionT) => void;
  rightContent?: React.ReactNode;
  style?: React.CSSProperties;
  sx?: SystemStyleObject;
}

export default function DroppableElement({
  children,
  isDisabled = false,
  isLastElement = false,
  onDrop,
  rightContent,
  style,
  sx,
}: DroppableElementProps) {
  const [dropTarget, setDropTarget] = React.useState<DropTargetT | null>(null);
  const elementRef = React.useRef<HTMLDivElement>(null);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (isDisabled) return;
    event.preventDefault();
    event.stopPropagation();

    const rect = event.currentTarget.getBoundingClientRect();
    const cursorY = event.clientY;
    const midpointY = rect.top + rect.height / 2;

    setDropTarget({
      index: 0,
      position: cursorY < midpointY ? 'top' : 'bottom',
    });
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    if (isDisabled) return;

    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    const cursorX = event.clientX;
    const cursorY = event.clientY;

    const tolerance = 0;

    const isCursorInside =
      cursorX >= rect.left - tolerance &&
      cursorX <= rect.right + tolerance &&
      cursorY >= rect.top - tolerance &&
      cursorY <= rect.bottom - tolerance;

    if (!isCursorInside) {
      setDropTarget(null);
    }
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = (event) => {
    if (isDisabled) return;
    event.preventDefault();
    event.stopPropagation();
    const type = event.dataTransfer.getData('type');
    onDrop?.(type, dropTarget?.position);
    setDropTarget(null);
  };
  return (
    <Box onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} role="group" style={style} sx={sx}>
      <HStack justify="space-between" align="flex-start">
        <Box flex={1}>
          <Spacer marginTop={theme.space[4]} />
          {dropTarget?.position === 'top' && (
            <Stack spacing={theme.space[4]}>
              <DropPlaceholder />
              <Divider />
            </Stack>
          )}
          <Stack
            ref={elementRef}
            style={{
              paddingBottom: dropTarget?.position === 'bottom' ? theme.space[4] : 0,
            }}
            spacing={4}
          >
            {children}
            {!isLastElement && <Divider />}
          </Stack>
          {dropTarget?.position === 'bottom' && (
            <Stack spacing={theme.space[4]}>
              {isLastElement && <Divider />}
              <DropPlaceholder />
              {!isLastElement && <Divider />}
            </Stack>
          )}
        </Box>
        {rightContent}
      </HStack>
    </Box>
  );
}

function DropPlaceholder() {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        backgroundColor: '#F3F6F9',
        border: '2px dashed',
        borderColor: '#A8B1E3',
        borderRadius: theme.radii.md,
      }}
    ></Box>
  );
}
