import { Box, Stack, HStack, Divider } from '@chakra-ui/core';
import { useEffect } from 'react';
import { SchemaBuilderProps } from '../formBuilderSchema';
import React from 'react';
import AddFollowUp from './AddFollowUp';
import FollowUpQuestionSchemaBuilder from './FollowUpQuestionSchemaBuilder';
import { uniq } from 'lodash';

interface FollowUpSchemaBuilderProps extends SchemaBuilderProps {
  // onChange: (schema: FieldSchema) => void;
  followUpLabel: number;
}
export default function FollowUpSchemaBuilder({
  defaultValue: parentDefaultValue,
  language,
  onChange,
  followUpDragOver,
  path: parentPath,
  isAdvanced,
  sectionIndex,
  fieldIndex,
  followUpLabel,
}: FollowUpSchemaBuilderProps) {
  // option ids which have follow-ups
  const [followUpOptions, setFollowUpOptions] = React.useState<string[]>([]);
  const path = [...parentPath, 'followUpQuestionList'];

  useEffect(() => {
    setFollowUpOptions(
      parentDefaultValue?.followUpQuestionList?.reduce((acc, v) => {
        return uniq([...acc, v?.optionId!]);
      }, [] as string[]) || [],
    );
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const followUpQuestions = [...(parentDefaultValue?.followUpQuestionList ?? [])];
    const newFollowUpQuestions = followUpQuestions?.map((item) => {
      if (item.parent !== parentDefaultValue.id) {
        return { ...item, parent: parentDefaultValue.id };
      }
      return item;
    });

    const result = {
      ...parentDefaultValue,
      followUpQuestionList: newFollowUpQuestions,
    };
    onChange(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        followUpDragOver?.();
      }}
    >
      <Stack>
        <HStack>
          {!isAdvanced && (
            <AddFollowUp
              followUpOptions={followUpOptions}
              setFollowUpOptions={setFollowUpOptions}
              parentDefaultValue={parentDefaultValue}
            />
          )}
        </HStack>
        <Stack marginLeft="12px !important" divider={<Divider />} spacing={2}>
          {followUpOptions.map((optionId, index) => (
            <FollowUpQuestionSchemaBuilder
              followUpOptionId={optionId}
              followUpOptions={followUpOptions}
              setFollowUpOptions={setFollowUpOptions}
              defaultValue={parentDefaultValue}
              onChange={onChange}
              language={language}
              followUpDragOver={followUpDragOver}
              path={path}
              index={index}
              key={index}
              isAdvanced={isAdvanced}
              sectionIndex={sectionIndex}
              fieldIndex={fieldIndex}
              followUpLabel={followUpLabel}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}
