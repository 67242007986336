export enum AdministrationMenuItemEnum {
  RECRUITER_MANAGEMENT = 'recruiterManagement',
  TEAM_LOW_AVAILABILITY = 'teamLowAvailability',
  CALENDAR = 'calendar',
  POSITION_MANAGEMENT = 'positionManagement',
  TEAM_MANAGEMENT = 'teamManagement',
  CERTN_INTEGRATION = 'certnIntegration',
  JOB_LISTING_URL = 'jobListingUrl',
  UPLOAD_COMPANY_LOGO = 'uploadCompanyLogo',
  UNRESPONSIVE_PERIOD = 'unresponsivePeriod',
  HIRE_FORM_API_KEY = 'hireFormAPIKey',
  MODERN_HIRE = 'modernHire',
  LANGUAGE_EVALUATION_PHRASES = 'languageEvaluationPhrases',
  VIDEO_GALLERY = 'videoGallery',
  WEB_HOOK_URL = 'webhookUrl',
  IMAGE_GALLERY = 'imageGallery',
  COMPANY_MANAGEMENT = 'companyManagement',
}

export type AccountMenuItemEnabledCheck = {
  isRecruiterManagementFeatureEnabled: boolean;
  isPositionManagementFeatureEnabled: boolean;
  isTeamAvailabilityFeatureEnabled: boolean;
  isTeamManagementFeatureEnabled: boolean;
  isWebAppyEnabled: boolean;
  isCertnEnabled: boolean;
  isHireFormEnabled: boolean;
  isSuperAdmin: boolean;
  isVideoGalleryEnabled: boolean;
};
