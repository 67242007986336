import { Box, HStack, Text } from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../styles/customTheme';

interface InputPreviewProps {
  value: string;
  rightContent?: React.ReactNode;
}
export default function InputPreview({ value, rightContent }: InputPreviewProps) {
  return (
    <Box
      backgroundColor="#F3F6F9"
      borderRadius={theme.radii.md}
      padding={theme.space[2]}
      display="flex"
      // w="fit-content"
    >
      <HStack justify="space-between" align="center" w="100%">
        <Text fontSize="sm" color="#A0AEC0">
          {value}
        </Text>
        {rightContent}
      </HStack>
    </Box>
  );
}
