import { FormControl, FormErrorMessage, Stack } from '@chakra-ui/core';
import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import TextField from './TextField';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';
import useErrorHandling from '../useErrorHandling';

export default function LinkSchemaBuilder(props: SchemaBuilderProps) {
  return (
    <Stack>
      <LabelSchemaBuilder {...props} />
      <FormElementTextBuilder {...props} placeholder="Enter text here for link input..." />
      <LinkInput {...props} />
    </Stack>
  );
}

function LinkInput(props: SchemaBuilderProps) {
  const { defaultValue } = props;

  const path = [...props.path, 'link', props.language];

  const { validateError, error, hasError, inputRef } = useErrorHandling({
    path,
    valdationFor: 'link',
    onValidation: (value) => {
      return { ...props.defaultValue.link!, [props.language]: value };
    },
  });

  const [value, setValue] = React.useState(defaultValue?.link?.[props.language] ?? '');

  React.useEffect(() => {
    setValue(defaultValue?.link?.[props.language] ?? '');
  }, [defaultValue, props.language]);

  const handleBlur = () => {
    props.onChange({
      ...defaultValue,
      link: {
        ...defaultValue.link!,
        [props.language]: value,
      },
    });
  };

  const handleChange = (val: string) => {
    setValue(val);

    validateError(val);
  };
  return (
    <FormControl isInvalid={hasError}>
      <TextField
        ref={inputRef}
        type="url"
        placeholder="Paste your link here"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasError && (
        <FormErrorMessage mt={0} fontSize="xs">
          {error?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
