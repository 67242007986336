import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import {
  HrFormTemplateGetAllResponse,
  HrFormUpdateTemplateResponse,
  HrFromCreateTemplateResponse,
} from '../modules/administration/hireForm/formElements/formBuilderSchema';
import { CurrentSchema } from '../modules/administration/models/HRFomTemplateModel';

export class HRFormTemplateController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async getTemplates(account: string) {
    const path = `/api/v1/account/${account}/hire-form-templates/`;

    return await this.httpClient.get<HrFormTemplateGetAllResponse>(path);
  }
  async createTemplate(account: string, payload: { name: string; questions: any }) {
    const path = `/api/v1/account/${account}/hire-form-templates/questions/bulk`;
    return await this.httpClient.post<HrFromCreateTemplateResponse>(path, payload);
  }

  async getTemplateById(account: string, id: string) {
    const path = `/api/v1/account/${account}/hire-form-templates/${id}/questions`;
    return await this.httpClient.get<CurrentSchema>(path);
  }

  async updateTemplate(account: string, id: string, payload: { name: string; questions: any }) {
    const path = `/api/v1/account/${account}/hire-form-templates/${id}/questions/bulk`;
    return await this.httpClient.put<HrFormUpdateTemplateResponse>(path, payload);
  }

  async deleteTemplate(account: string, id: string) {
    const path = `/api/v1/account/${account}/hire-form-templates/${id}`;
    return await this.httpClient.delete(path);
  }

  async uploadDocument(account: string, payload: FormData) {
    const path = `/api/v1/account/${account}/hire-form-templates/document/upload`;
    return await this.httpClient.post<{ data: { url: string } }>(path, payload);
  }
}
