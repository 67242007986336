import React from 'react';
import { FormBuilderProps } from '../../formBuilder/FormBuilder';
import { FieldSchema, generateFormSchema, SchemaBuilderProps } from '../formBuilderSchema';
import { useHireFormContext } from '../../HireForm.context';
import DroppableElement from './DroppableElement';
import { Box, Button, HStack, keyframes } from '@chakra-ui/core';
import theme from '../../../../../styles/customTheme';
import { formBuilderSchemas, schemaFieldsByType } from '../../formBuilderSchemas/formBuilder.schema';
import isArray from 'lodash/isArray';
import { v4 as uuidv4 } from 'uuid';
import ElementSelectField from '../ElementSelctField';
import { HiTrash } from 'react-icons/hi';
import DeletePopover from '../advancedElements/DeletePopover';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MdDragIndicator } from 'react-icons/md';

interface FormBuilderElementProps extends Omit<FormBuilderProps, 'templateName' | 'setTemplateName'> {
  field: FieldSchema;
  fieldIndex: number;
  sectionIndex: number;
  isAdvancedSection: boolean;
  isLastElement?: boolean;
}

export default function FormElementBuilder({
  field,
  sectionIndex,
  isAdvancedSection,
  isLastElement,
  fieldIndex,
}: FormBuilderElementProps) {
  const { formSchema, setFormSchema, language } = useHireFormContext();

  const { timestamp, inputType, hideFieldSelector } = field;
  const { component: Component } = formBuilderSchemas[inputType];
  if (!Component) return null;

  const pathIndex = generateFormSchema(formSchema, language, true)?.findIndex((schema) => schema.id === field.id);

  const elementFields =
    schemaFieldsByType?.[field.field as keyof typeof schemaFieldsByType] || schemaFieldsByType?.[field.inputType];

  return (
    <DroppableElement
      onDrop={(type, dropTarget) => {
        if (type !== 'section') {
          const { defaultValue } = formBuilderSchemas[type as keyof typeof formBuilderSchemas];
          const newFormSchema = [...formSchema];
          const currentSection = formSchema[sectionIndex];
          let spliceIndex = fieldIndex;

          if (fieldIndex === formSchema.length - 1 && dropTarget === 'bottom') {
            spliceIndex = fieldIndex + 1;
          } else {
            if (dropTarget === 'top') {
              spliceIndex = fieldIndex;
            } else {
              spliceIndex = fieldIndex;
            }
          }
          if (isArray(defaultValue)) return;
          currentSection.fields.splice(spliceIndex, 0, { ...defaultValue, timestamp: Date.now(), id: uuidv4() });
          formSchema.map((section) => {
            if (section.section === currentSection.section) {
              return currentSection;
            }
            return section;
          });
          setFormSchema(newFormSchema);
        }
      }}
      sx={{
        '&:hover .hire-form-element-actions': {
          visibility: 'visible',
        },
      }}
      rightContent={
        <FormElementActions isAdvancedSection={isAdvancedSection} sectionIndex={sectionIndex} fieldIndex={fieldIndex} />
      }
      isLastElement={isLastElement}
      isDisabled={isAdvancedSection}
    >
      <Box backgroundColor={theme.colors.white}>
        <HStack
          justify="space-between"
          align="flex-start"
          position="relative"
          _after={{
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            boxSizing: 'content-box',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            animation: timestamp > Date.now() - 1000 ? `${animation} 500ms ease-out` : '',
            zIndex: -10,
            borderRadius: theme.radii.md,
          }}
        >
          <Box flexGrow={1} transition={'flex-grow 0.2s ease-in-out'}>
            <MemoizedRenderElement
              field={field}
              onChange={(value) => {
                const newFormSchema = [...formSchema];
                newFormSchema[sectionIndex].fields[fieldIndex] = value;
                setFormSchema(newFormSchema);
              }}
              language={language}
              isAdvanced={isAdvancedSection}
              sectionIndex={sectionIndex}
              fieldIndex={fieldIndex}
              pathIndex={pathIndex}
              labelRightRenderer={
                ((elementFields && elementFields.length > 0) || !hideFieldSelector) &&
                !isAdvancedSection && (
                  <ElementSelectField
                    defaultValue={field}
                    sectionIndex={sectionIndex}
                    fieldIndex={fieldIndex}
                    options={elementFields ?? []}
                    onChange={(field) => {
                      const updatedFormSchema = [...formSchema];
                      updatedFormSchema[sectionIndex].fields[fieldIndex].field = field;
                      setFormSchema(updatedFormSchema);
                    }}
                  />
                )
              }
            />
          </Box>
        </HStack>
      </Box>
    </DroppableElement>
  );
}

interface FormBuilderActionsProps {
  isAdvancedSection: boolean;
  sectionIndex: number;
  fieldIndex: number;
}

function FormElementActions({ isAdvancedSection, sectionIndex, fieldIndex }: FormBuilderActionsProps) {
  const { formSchema, setFormSchema } = useHireFormContext();
  const { setErrors } = useStoreActions((s) => s.hrFormTemplate);
  const { errors } = useStoreState((s) => s.hrFormTemplate);

  return (
    <HStack>
      {!isAdvancedSection && (
        <HStack flexShrink={0} marginTop="0 !important" visibility="hidden" className="hire-form-element-actions">
          <SortableAnchor />
          <DeletePopover
            popoverTrigger={
              <Button borderRadius="50%" colorScheme="red" style={{ aspectRatio: '1/1' }} padding={1} size="sm">
                <HiTrash />
              </Button>
            }
            onYes={() => {
              const newFormSchema = [...formSchema];
              newFormSchema[sectionIndex].fields.splice(fieldIndex, 1);
              setErrors([...errors]?.filter((e) => e.path[1] !== fieldIndex));
              setFormSchema(newFormSchema);
            }}
          />
        </HStack>
      )}
    </HStack>
  );
}

const SortableAnchor = SortableHandle(() => (
  <Button as="div" borderRadius="50%" cursor="grab" backgroundColor="gray" style={{ aspectRatio: '1/1' }} padding={1} size="sm">
    <MdDragIndicator />
  </Button>
));

function RenderElement({
  field,
  onChange,
  fieldIndex,
  pathIndex,
  ...rest
}: { field: FieldSchema; pathIndex: number } & Omit<SchemaBuilderProps, 'defaultValue' | 'path' | 'followUpLabel'>) {
  const { inputType } = field;

  const { component: Component } = formBuilderSchemas[inputType];
  if (!Component) return null;

  const path = ['questions', pathIndex];

  return <Component onChange={onChange} {...rest} defaultValue={field} path={path} fieldIndex={fieldIndex} followUpLabel={0} />;
}

const MemoizedRenderElement = React.memo(RenderElement);

const animation = keyframes`
  0% {
    background-color: ${theme.colors.blue[100]};
    outline: 2px solid ${theme.colors.blue[100]};
    outline-offset: 2px;
    padding: 6px;
    z-index: 2;
  }
  60% {
    outline: 2px solid ${theme.colors.blue[50]};
    outline-offset: 7px;
  }
  100% {
    background-color: transparent;
    outline: 0px solid transparent;
    outline-offset: 10px;
    padding: 12px;
    z-index: -1;
  }
`;

export const SortableFormElementBuilder = SortableElement(FormElementBuilder);
