import { Box, Center, css, Global, Spinner, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import FormBuilder from './formBuilder/FormBuilder';
import FormPreview from './formElements/FormPreview';
import { useStoreState } from '../../../models/hooks';
import FormComponentContainer from './formComponents/FormComponentContainer';
import { HireFormProvider } from './HireForm.context';

export default function HireformView() {
  const { isLoading } = useStoreState((state) => state.hrFormTemplate);

  return (
    <>
      <GlobalStyles />
      <HireFormProvider>
        <Box
          display="flex"
          flexDirection="row"
          backgroundColor="#F3F3F3"
          h="100%"
          minHeight="0"
          maxHeight="100vh"
          overflow="hidden"
          sx={{
            html: {
              overflow: 'hidden',
            },
          }}
        >
          <FormComponentContainer />

          {isLoading ? (
            <Center flex="0 0 50%" overflowY="auto" overflowX="hidden" minHeight="0" height="100%">
              <Stack align="center">
                <Spinner />
                <Text fontSize="sm" color="gray[400]">
                  Please wait building your form...
                </Text>
              </Stack>
            </Center>
          ) : (
            <FormBuilder />
          )}

          <Box flex="0 0 25%" overflowY="auto" overflowX="hidden" minHeight="0" height="100%">
            {<FormPreview />}
          </Box>
        </Box>
      </HireFormProvider>
    </>
  );
}

const GlobalStyles = () => (
  <Global
    styles={css`
      html {
        overflow: hidden;
      }
    `}
  />
);
