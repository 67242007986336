import Joi from 'joi';

// Define the LabelSchema
const LabelSchema = Joi.object({
  en: Joi.string().required().min(1).messages({
    'any.required': 'This field is required',
    'string.empty': 'This field is required',
    'string.min': 'This field is required',
  }), // At least 'en' is required
  fr: Joi.string().when('en', {
    is: Joi.valid(null, '').required(), // Check if `en` is missing
    then: Joi.string()
      .required()
      .messages({ 'any.required': 'This field is required [en]', 'string.empty': 'This field is required [en]' }),
    otherwise: Joi.allow(null, ''),
  }),
});

// Define the OptionSchema
const OptionSchema = Joi.object({
  id: Joi.string()
    .guid({ version: ['uuidv4'] })
    .required(),
  text: LabelSchema.required(),
  tag: Joi.string().allow(null, '').optional(),
});

// Define the DocumentReviewSchema
const DocumentReviewSchema = Joi.object({
  acknowledgeContent: LabelSchema.required(),
  description: LabelSchema.required(),
  confirmText: LabelSchema.required(),
  url: Joi.object({
    en: Joi.string().uri().required().messages({
      'any.required': 'Please upload a document',
      'string.empty': 'Please upload a document',
    }),
    fr: Joi.string()
      .uri()
      .when('en', {
        is: Joi.valid(null, '').required(), // Check if `en` is missing
        then: Joi.string()
          .required()
          .messages({ 'any.required': 'Please upload a document [en]', 'string.empty': 'Please upload a document [en]' }),
        otherwise: Joi.allow(null, ''),
      }),
  }), // URL must be a valid URI
}).allow(null); // documentReview can be null

const FollowUpSchema = Joi.object({
  id: Joi.string()
    .guid({ version: ['uuidv4'] })
    .required(),
  label: LabelSchema.required(),
  field: Joi.string().required(),
  inputType: Joi.string().required(),
  text: LabelSchema.required(),
  isInfo: Joi.boolean(),
  order: Joi.number().integer().positive().required(),
  optionList: Joi.array().items(OptionSchema).allow(null),
  isFollowUp: Joi.boolean().optional(),
  min: Joi.number().integer().positive().allow(null),
  max: Joi.number().integer().positive().allow(null),
  link: LabelSchema.allow(null),
  parent: Joi.string()
    .guid({ version: ['uuidv4'] })
    .allow(null),
  optionId: Joi.string()
    .guid({ version: ['uuidv4'] })
    .allow(null),
  followUpQuestionList: Joi.array().items(Joi.link('#followUpSchema')).allow(null),
  section: Joi.string().required(),
}).custom((value, helpers) => {
  if (value.isFollowUp !== true) {
    return helpers.error('any.invalid', {
      message: `Follow-up question with id ${value.id} must have 'isFollowUp' set to true.`,
    });
  }
  return value;
});

// Define the FieldSchema
const QuestionSchema = Joi.object({
  id: Joi.string()
    .guid({ version: ['uuidv4'] })
    .required(),
  label: LabelSchema.required(),
  field: Joi.string().required(),
  inputType: Joi.string().required(),
  text: LabelSchema.required(),
  isInfo: Joi.boolean(),
  order: Joi.number().integer().positive().required(),
  optionList: Joi.array().items(OptionSchema).allow(null),
  isFollowUp: Joi.boolean().optional(),
  followUpQuestionList: Joi.array().items(FollowUpSchema).allow(null),
  min: Joi.number().integer().positive().allow(null),
  max: Joi.number().integer().positive().allow(null),
  link: LabelSchema.allow(null),
  parent: Joi.string()
    .guid({ version: ['uuidv4'] })
    .allow(null),
  optionId: Joi.string()
    .guid({ version: ['uuidv4'] })
    .allow(null),
  documentReview: DocumentReviewSchema, // Optional but required fields inside documentReview if present
  enableWorkPermit: Joi.boolean().optional(),
  section: Joi.string().required(),
}).custom((value, helpers) => {
  // Validate `followUpQuestionList` optionIds
  if (value.followUpQuestionList && value.optionList) {
    const validOptionIds = value.optionList.map((option: { id: string }) => option.id);
    for (const followUp of value.followUpQuestionList) {
      if (followUp.optionId && !validOptionIds.includes(followUp.optionId)) {
        return helpers.error('any.invalid', {
          message: `Follow-up question with id ${followUp.id} has an invalid optionId: ${followUp.optionId}.`,
        });
      }
    }
  }

  // Validate unique IDs in `optionList`
  if (value.optionList) {
    const optionIds = value.optionList.map((option: { id: string }) => option.id);
    const uniqueOptionIds = new Set(optionIds);
    if (uniqueOptionIds.size !== optionIds.length) {
      return helpers.error('any.invalid', {
        message: `Duplicate IDs found in optionList for field with id ${value.id}.`,
      });
    }
  }

  return value;
});
// Set the ID for the base schema

// Define the Question schema that extends from the base schema
// const QuestionSchema = BaseQuestionSchema.keys({
//   section: Joi.string().required(),
// })
//   .id('questionSchema')

// Define the root schema
const QuestionsSchema = Joi.array()
  .items(QuestionSchema)
  .custom((values, helpers) => {
    // Check for unique IDs across the entire form
    const ids = values.map((item: { id: string }) => item.id);
    const uniqueIds = new Set(ids);
    if (uniqueIds.size !== ids.length) {
      return helpers.error('any.invalid', {
        message: 'Duplicate IDs found in the form data.',
      });
    }

    return values;
  });

const TemplateSchema = Joi.object({
  name: Joi.string().required(),
  questions: QuestionsSchema.required(),
});

export { QuestionSchema, QuestionsSchema, TemplateSchema };
