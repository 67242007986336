import { Editable, EditablePreview, EditableInput, Input, FormControl, FormErrorMessage } from '@chakra-ui/core';
import { SchemaBuilderProps } from './formBuilderSchema';
import React, { useEffect } from 'react';
import useErrorHandling from '../useErrorHandling';
import theme from '../../../../styles/customTheme';

const LabelSchemaBuilder = React.memo(({ ...props }: SchemaBuilderProps) => {
  const value = props.defaultValue?.label?.[props.language];
  const path = [...props.path, 'label', props.language];

  const [inputValue, setInputValue] = React.useState(value);

  const { validateError, error, hasError, inputRef } = useErrorHandling({
    path,
    valdationFor: 'label',
    onValidation: (value) => {
      return { ...props.defaultValue.label!, [props.language]: value };
    },
  });

  useEffect(() => {
    setInputValue(value);
    // eslint-disable-next-line
  }, [value]);

  function handleInputChange(val: string) {
    setInputValue(val);
    validateError(val);
  }

  function handleInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    const val = e.target.value!;

    const formLabel = { ...props?.defaultValue?.label!, [props.language]: val };

    props.onChange({ ...props.defaultValue!, label: formLabel });
  }

  if (props?.isAdvanced && !value) return null;

  return (
    <FormControl isInvalid={hasError}>
      <Editable
        value={inputValue}
        placeholder="Click to add Label"
        fontSize="xs"
        width="fit-content"
        isDisabled={props?.isAdvanced}
        isPreviewFocusable={!props?.isAdvanced}
        onChange={handleInputChange}
        isTruncated
        data-testid="label-editable"
        selectAllOnFocus={false}
      >
        <EditablePreview
          width={'100%'}
          style={{
            ...(inputValue?.length
              ? {
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  color: hasError ? theme.colors.red[500] : 'black',
                }
              : {
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  color: hasError ? theme.colors.red[500] : '#4A5568',
                }),
            ...{
              wordWrap: 'break-word',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
            },
          }}
        />
        <Input
          as={EditableInput}
          width={'100%'}
          isInvalid={hasError}
          fontSize="xs"
          // variant="unstyled"
          size="sm"
          onBlur={handleInputBlur}
          style={{
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}
          ref={inputRef}
          data-testid="label-input"
        />
      </Editable>
      <FormErrorMessage mt={0} fontSize="xs">
        {error?.message}
      </FormErrorMessage>
    </FormControl>
  );
});

export default LabelSchemaBuilder;
